import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";
import Calendar from '@event-calendar/core';
import TimeGrid from '@event-calendar/time-grid';
import Interaction from '@event-calendar/interaction';


export default class TicketShowServices {
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "services";
    private toastr: any;
    private editModal: bootstrap.Modal;
    private ulUserList: any;
    private calendar: Calendar | undefined;
    protected calendarDropdown: HTMLElement | undefined;
    protected calendarCurrentUserId = "";
    protected calendarCurrentServiceId = "";
    private calendarResources: any[] = [];

    protected calendarAppointmentList: any = {}
    protected calendarDropdownActive = false;
    protected calendarDropdownActiveEvent = {}


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.contract_show_services')
        this.ulUserList = jQuery('#ticket_service_user_list')
        this.editModal = new bootstrap.Modal((document.querySelector("#editTicketServiceMap") as HTMLElement));
        this.calendarDropdown = (document.querySelector("#ticket_service_dropdown") as HTMLElement);
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...TicketServiceMapEntity.getEntityData(elem), ticket_id: this.parent.id}
    }

    bindListeners() {
        (document.querySelector("#ticket_service_save_book") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const uuid = await this.saveRecord();
            setTimeout(() => {
                const a = document.querySelector(`a.edit-record[data-id='${uuid}']`) as HTMLElement;
                console.log("uuid", uuid, a)
                if (a) {
                    a.click();
                }
            }, 400);
        });
        (document.querySelector("#ticket_service_dropdown_delete") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            // @ts-ignore
            const id = this.calendarDropdownActiveEvent.id
            await Utils.entity.destroy(id, 'calendar_appointments')
            if (this.calendar) {
                this.calendar.removeEventById(id)
            }
        })
        document.body.addEventListener("click", (e) => {
            const target = e.target as HTMLElement;
            if (this.calendarDropdown && this.calendarDropdown.classList.contains("show") && !target.classList.contains("ec-event-body")) {
                this.calendarDropdown.classList.remove("show");
            }
        })
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                e.preventDefault();
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity, this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('ticket_service_map.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('ticket_service_map.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });

        this.ulUserList.delegate("li", "click", (e: any) => {
            this.onUserClick(e);
        });

        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity, this.parent.id, this.parent.entity)
            if (requestData.status === 200) {
                this.calendarCurrentServiceId = id;
                const data = requestData.data[0]
                const form = (document.querySelector("#editTicketServiceMapForm") as HTMLFormElement);
                await Utils.updateElements(data, '', (document.querySelector("#editTicketServiceMap") as HTMLElement));
                this.editModal.show();

                this.calendarResources = [];
                this.calendarAppointmentList = {};
                data.service.workPackage.capableUsers.forEach((u: any, i: number) => {
                    this.calendarResources.push({id: i + 1, title: u.name, color: `#${Utils.genCalendarColor(i)}`, uuid: u.uuid})
                })

                this.ulUserList.html("");
                for (const resource of this.calendarResources) {
                    this.calendarAppointmentList[resource.uuid] = [];
                    this.ulUserList.html(this.ulUserList.html() + `<li data-resource-id='${resource.id}' data-resource-uuid='${resource.uuid}' data-resource-name='${resource.title}' data-color='${resource.color}' class="list-group-item d-flex justify-content-between align-items-start"> <div class="ms-2 me-auto"> <div class="fw-bold">${resource.title}</div> </div> <span class="badge rounded-pill" style="background-color:${resource.color};">&nbsp;</span> </li>`)
                    const e = await Utils.entity.get(resource.uuid, "users")
                    if(e.status === 200) {
                        const userData = e.data[0]
                        const appointments = userData.calendarAppointments;
                        for(const appointment of appointments) {
                            let title = appointment.title
                            if (appointment.external) {
                                title = `[EXTERN]${title}`
                            }
                            this.calendarAppointmentList[resource.uuid].push({id: appointment.uuid, start: appointment.from, end: appointment.to, resourceId: resource.id, title: title, color: resource.color, editable: !appointment.external, external: appointment.external})
                        }
                    }
                }

                if (document.querySelector("#ticket_service_calendar")) {
                    (document.querySelector("#ticket_service_calendar") as HTMLElement).innerHTML = "";
                }

                this.createCalendar();

                function _pad(num: any) {
                    let norm = Math.floor(Math.abs(num));
                    return (norm < 10 ? '0' : '') + norm;
                }
            }

        });
        (document.querySelector("#addnewTicketServiceMapForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            await this.saveRecord()
        });

        (document.querySelector("#editTicketServiceMapForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editTicketServiceMapForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity, this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('ticket_service_map.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }

    createCalendar() {
        this.calendar = new Calendar({
            target: document.getElementById('ticket_service_calendar'),
            props: {
                plugins: [TimeGrid, Interaction],
                options: {
                    views: {
                        timeGridWeek: {pointer: true}
                    },
                    resources: this.calendarResources,
                    scrollTime: '09:00:00',
                    allDaySlot: false,
                    events: [],
                    nowIndicator: true,
                    selectable: false,
                    firstDay: 1,
                    eventDrop: async (info: any) => {
                        const e = info.event;
                        const id = e.id;
                        const start = e.start.toUTCString();
                        const end = e.end.toUTCString();
                        await Utils.entity.upsert({
                            uuid: id,
                            from: start,
                            to: end
                        }, "calendar_appointments")

                    },
                    eventClick: (info: any) => {
                        console.log(info)
                        if (this.calendarDropdown) {
                            if (this.calendarDropdownActive) {
                                this.calendarDropdownActive = false;
                                this.calendarDropdownActiveEvent = {}
                                this.calendarDropdown.classList.remove("show");
                            } else {
                                const e = info.jsEvent;
                                e.preventDefault();
                                //@ts-ignore
                                const offset = document.querySelector("#editTicketServiceMap .modal-content").getBoundingClientRect()
                                const bodyOffsets = document.body.getBoundingClientRect();
                                const x = e.pageX - 50;
                                const y = e.pageY - 50 - offset.top;
                                this.calendarDropdown.style.top = `${y}px`;
                                this.calendarDropdown.style.left = `${x}px`;
                                this.calendarDropdown.classList.add("show")
                                this.calendarDropdownActive = true;

                                this.calendarDropdownActiveEvent = info.event;
                            }
                        }

                    },
                    eventResize: async (info: any) => {
                        const e = info.event;
                        const id = e.id;
                        const start = e.start.toUTCString();
                        const end = e.end.toUTCString();
                        await Utils.entity.upsert({
                            uuid: id,
                            from: start,
                            to: end
                        }, "calendar_appointments")
                    },
                    select: async (info: any) => {
                        const start = info.start.toUTCString();
                        const end = info.end.toUTCString();
                        const reply = await Utils.entity.upsert({
                            from: start,
                            to: end,
                            user_id: this.calendarCurrentUserId,
                            ticket_service_map_id: this.calendarCurrentServiceId
                        }, "calendar_appointments")
                        const appointment = reply.data[0]
                        if (this.calendar) {
                            const r = this.calendarResources.filter(r => r.uuid === this.calendarCurrentUserId)[0];
                            this.calendar.unselect()
                            let title = appointment.title
                            if (appointment.external) {
                                title = `[EXTERN]${title}`
                            }
                            this.calendar.addEvent({id: appointment.uuid, start: appointment.from, end: appointment.to, resourceId: r.id, title: title, color: r.color})
                        }
                    }
                }
            }
        });
    }

    onUserClick(e: any) {
        if(this.calendar) {
            const currentTarget = e.currentTarget;
            const resourceId = currentTarget.getAttribute("data-resource-id");
            const uuid = currentTarget.getAttribute("data-resource-uuid");
            const color = currentTarget.getAttribute("data-color");
            this.calendar.setOption("selectable", true);
            this.calendar.setOption("selectBackgroundColor", color);
            if (currentTarget.parentNode.querySelector("li.active")) {
                currentTarget.parentNode.querySelector("li.active").classList.remove("active");
            }
            currentTarget.classList.add("active");
            if (this.calendarCurrentUserId) {
                this.calendarAppointmentList[this.calendarCurrentUserId].forEach((event: any) => {
                    if(this.calendar) {
                        this.calendar.removeEventById(event.id)
                    }
                })
            }
            this.calendarCurrentUserId = uuid;
            const events = this.calendarAppointmentList[uuid]
            if (events) {
                events.forEach((appointment: any) => {
                    if (this.calendar) {
                        let title = appointment.title
                        if (appointment.external) {
                            title = `[EXTERN]${title}`
                        }
                        this.calendar.addEvent({id: appointment.id, start: appointment.start, end: appointment.end, resourceId: resourceId, title: title, color: color, editable: appointment.editable, extendedProps: {external: appointment.external}})
                    }
                })
            }
        }
    }

    async saveRecord() {
        const elem = document.querySelector("#addnewTicketServiceMapForm") as HTMLFormElement;
        const valid = elem.checkValidity();
        if (valid) {
            const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity, this.parent.id, this.parent.entity)
            if (r.status === 200) {
                await this.datatable.ajax.reload();
                const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddService") as HTMLElement))
                if (bsElem) {
                    bsElem.hide();
                }
                this.toastr.success(`${Utils.translate('ticket_service_map.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                return r.data[0].uuid
            }
            return null;
        }
        return null;
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/tickets/${this.parent.id}/services`,
            columns: [
                {data: 'name'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="#" data-id="${data}" class="text-body edit-record">
                                   ${full.name}
                                  </a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.service.workPackage.name}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.assignees.map((a: { name: string; }) => a.name).join(", ")}`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        console.log("full", full)
                        return `${full.assignees.map((a: any) => {
                            return a.calendarAppointments.map((b: any) => {
                                return `${b.from} - ${b.to}`
                            })
                        }).join("<br />")}`;
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" data-entity="customer_locations" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddService'
                    }
                }
            ]
        });
    }
}